import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "./Firebase";

import "../styles/Result.scss";

async function getOpenAiResponse(payload) {
  console.log("payload", payload);
  const getOpenAiResponseFunc = httpsCallable(functions, "getOpenAiResponse");
  const stringPayload = JSON.stringify(payload);

  const result = await getOpenAiResponseFunc(stringPayload);
  return result.data.result;
}

function Result({ params, setParams }) {
  const [result, setResult] = useState("");

  const handleClick = async () => {
    const payload = {
      model: params.model,
      messages: [
        {
          role: "system",
          content: params.systemPreview,
        },
        {
          role: "user",
          content: params.userPreview,
        },
      ],
      temperature: params.temperature,
      max_tokens: params.max_tokens,
      top_p: params.top_p,
      frequency_penalty: params.frequency_penalty,
      presence_penalty: params.presence_penalty,
      stop: params.stop,
    };
    setResult("Waiting for OpenAI response...");
    const result = await getOpenAiResponse(payload);
    setResult(result);
    setParams((prevParams) => ({
      ...prevParams,
      result,
    }));
  };

  return (
    <div className="ResultBox">
      <div className="ResultHeader">
        <button className="ResultButton" onClick={handleClick}>
          Run
        </button>
      </div>
      <pre className="ResultText">{result || params.result}</pre>
    </div>
  );
}

export default Result;
