import React, { useState, useEffect } from "react";
import Handlebars from "handlebars";
import "../styles/Editor.scss";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

function Editor({ params, setParams }) {
  const [userTemplate, setUserTemplate] = useState(params.userPrompt);
  const [userError, setUserError] = useState(false);

  useEffect(() => {
    try {
      const systemPreview = Handlebars.compile(userTemplate)(params.json);
      setParams((prevParams) => ({
        ...prevParams,
        systemPreview,
      }));
      setUserError(false);
    } catch (e) {
      setUserError(true);
    }
  }, [setParams, userTemplate, params.json]);

  useEffect(() => {
    try {
      const userPreview = Handlebars.compile(userTemplate)(params.json);
      setParams((prevParams) => ({
        ...prevParams,
        userPreview,
      }));
      setUserError(false);
    } catch (e) {
      setUserError(true);
    }
  }, [setParams, userTemplate, params.json]);

  return (
    <div className="Editor">
      <div className="EditorEdit">
        <AceEditor
          className={`AceEditor ${userError ? "AceEditor-error" : ""}`}
          placeholder="Enter user prompt"
          mode="handlebars"
          theme="textmate"
          name="blah2"
          onChange={(val) => setUserTemplate(val)}
          fontSize={14}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine={true}
          value={userTemplate}
          wrapEnabled={true}
          width={"100%"}
          height="400px"
          setOptions={{
            indentedSoftWrap: false,
          }}
        />
      </div>
      <div className="EditorTextarea">
        <pre className="EditorPreview">{params.userPreview}</pre>
      </div>
    </div>
  );
}

export default Editor;
