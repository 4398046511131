import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import React, { useEffect, useRef } from "react";
import "../styles/Import.scss";

export const ControlledJsonEditor = ({ value, onChange }) => {
  const jsonEditorRef = useRef();
  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor && value) {
      editor.update(value);
    }
  }, [jsonEditorRef, value]);

  useEffect(() => {
    const editor =
      jsonEditorRef &&
      jsonEditorRef.current &&
      jsonEditorRef.current.jsonEditor;
    if (editor && value) {
      editor.expandAll();
    }
  }, []);

  return (
    <JsonEditor
      htmlElementProps={{ style: { height: 400 } }}
      ref={jsonEditorRef}
      allowedModes={["tree", "code", "text"]}
      // mode={"code"}
      value={value}
      onChange={onChange}
    />
  );
};

function Import({ params, setParams }) {
  const handleEdit = (json) => {
    setParams((prevParams) => ({
      ...prevParams,
      json,
    }));
  };

  return (
    <div className="Import">
      <ControlledJsonEditor value={params.json} onChange={handleEdit} />
    </div>
  );
}

export default Import;
