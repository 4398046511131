import React, { useState } from "react";
import "../styles/App.scss";
import Import from "./Import";
import Editor from "./Editor";
import Result from "./Result";
import Modal from "react-modal";

Modal.setAppElement("#root");

function Admin() {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
  const [params, setParams] = useState({
    model: "gpt-3.5-turbo",
    temperature: 0,
    max_tokens: 1024,
    stop: "",
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    json: {
      user: {
        name: "Alex",
        feature: "Analytics Free",
        subscription: "Free",
        teamSize: 5,
        industry: "Marketing",
      },
      availableFeatures: [
        {
          name: "Analytics Pro",
          description: "Advanced tool for analytics",
        },
        {
          name: "Team Planner",
          description: "Collaborative space to explore and share ideas",
        },
      ],
    },
    systemPrompt: `
`,

    userPrompt: `You are an expert account manager. Write one paragraph message recommending which other features the user should try.

The user characteristics:
- Name: {{user.name}}
- Team size: {{user.teamSize}} people
- Used feature: {{user.feature}}
- Industry: {{user.industry}}

Features available to the user:
{{#each availableFeatures}}
- Feature: {{this.name}}
Description: {{this.description}}
{{/each}}
`,

    result: `Hi Alex,

Based on your team size and industry, I would recommend trying out the Analytics Pro feature. It is an advanced tool for analytics that can provide you with more in-depth insights and data analysis. This can help you make more informed decisions and optimize your marketing strategies. Additionally, I suggest exploring the Team Planner feature, which is a collaborative space designed for brainstorming and sharing ideas. It can enhance your team's productivity and foster creativity by allowing everyone to contribute and collaborate effectively. Let me know if you have any questions or need further assistance!

Best regards,
[Your Name]`,
  });

  // return (
  //   <div className="Admin">
  //     <Import params={params} setParams={setParams} />
  //     <Editor params={params} setParams={setParams} />
  //     <Result params={params} setParams={setParams} />
  //   </div>
  // );
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => setWindowWidth(window.innerWidth);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div id="page-top">
      <div id="svgLargeDesktop">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            // margin: "auto",
            background: "#ffffff",
            display: "block",
            zIndex: -10,
            position: "absolute",
            transform: "translateZ(0)",

            // margin: "auto",
            // background: "#ffffff",
            // display: "block",
            // zIndex: "1",
            // position: "relative",
            // marginTop: "-30px",
            // webkitTransformStyle: "preserve-3d",
            // webkitBackfaceVisibility: "hidden",
          }}
          width="1920"
          height="944"
          // width="100%"
          // height="100%"
          // preserveAspectRatio="xMidYMid"
          preserveAspectRatio="none"
          viewBox="0 0 1920 944"
        >
          <g transform="translate(960,472) scale(-1,1) translate(-960,-472)">
            <linearGradient
              id="lg-0.7557432522653886"
              x1="0"
              x2="1"
              y1="0"
              y2="0"
            >
              <stop stopColor="#ff00ff" offset="0"></stop>
              <stop stopColor="#00ffff" offset="1"></stop>
            </linearGradient>
            <path d="" fill="url(#lg-0.7557432522653886)" opacity="0.4">
              <animate
                attributeName="d"
                dur="10s"
                repeatCount="indefinite"
                keyTimes="0;0.333;0.667;1"
                calcMode="spline"
                keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                begin="0s"
                values="M0 0M 0 1017.91396681958Q 192 900.3524789414017 384 896.9498296664406T 768 893.3927248484894T 1152 802.2438458816765T 1536 783.7510200939063T 1920 662.9137412103835L 1920 241.32808687576934Q 1728 163.74511832323955 1536 159.26419967183722T 1152 116.68319534151684T 768 146.49411269161317T 384 -16.96972319587701T 0 29.607063234747727Z;M0 0M 0 927.1606642104192Q 192 898.1617199122762 384 892.1097181735062T 768 850.5220209199082T 1152 818.7899847735704T 1536 747.1362384833538T 1920 626.8716286532858L 1920 242.72605902777036Q 1728 176.19986822939018 1536 173.37896240156272T 1152 180.93530262460013T 768 130.7019025706466T 384 72.13999521197178T 0 13.498317904977796Z;M0 0M 0 925.7953616544554Q 192 864.4463298536016 384 857.0538222574039T 768 882.2147184269269T 1152 780.4121745664679T 1536 745.5339300548243T 1920 716.7187930658653L 1920 235.8069680991601Q 1728 224.04969172266394 1536 214.6323617812863T 1152 95.24144279502093T 768 112.6228419544052T 384 12.170997016638438T 0 25.853260288377044Z;M0 0M 0 1017.91396681958Q 192 900.3524789414017 384 896.9498296664406T 768 893.3927248484894T 1152 802.2438458816765T 1536 783.7510200939063T 1920 662.9137412103835L 1920 241.32808687576934Q 1728 163.74511832323955 1536 159.26419967183722T 1152 116.68319534151684T 768 146.49411269161317T 384 -16.96972319587701T 0 29.607063234747727Z"
              ></animate>
            </path>
            <path d="" fill="url(#lg-0.7557432522653886)" opacity="0.4">
              <animate
                attributeName="d"
                dur="10s"
                repeatCount="indefinite"
                keyTimes="0;0.333;0.667;1"
                calcMode="spline"
                keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                begin="-2.5s"
                values="M0 0M 0 963.397883072352Q 192 883.0171563826415 384 874.0516192578879T 768 872.0756641699229T 1152 761.644260540688T 1536 729.23117778107T 1920 667.6054871332584L 1920 227.0302698479697Q 1728 207.34806863904498 1536 198.54856849892474T 1152 125.8133164470598T 768 86.20479463337563T 384 0.9938323210200224T 0 -28.100294451345775Z;M0 0M 0 960.0210374001439Q 192 882.591825931447 384 873.8197890658031T 768 865.3586670981484T 1152 769.5167942221933T 1536 760.1466166548827T 1920 641.1312939324598L 1920 253.45773310211848Q 1728 183.9850662678423 1536 175.12884255170522T 1152 120.55957238581695T 768 47.15358177394921T 384 12.317441087287023T 0 -69.6052221982157Z;M0 0M 0 958.7994603557768Q 192 947.416506062941 384 942.7251622728683T 768 828.8779602599087T 1152 769.6451973784233T 1536 697.2947074835932T 1920 692.374154271959L 1920 239.09334254794229Q 1728 176.41478687031415 1536 175.31776047006065T 1152 119.72772233598951T 768 79.69476810560431T 384 58.5158191427098T 0 6.752932865464345Z;M0 0M 0 963.397883072352Q 192 883.0171563826415 384 874.0516192578879T 768 872.0756641699229T 1152 761.644260540688T 1536 729.23117778107T 1920 667.6054871332584L 1920 227.0302698479697Q 1728 207.34806863904498 1536 198.54856849892474T 1152 125.8133164470598T 768 86.20479463337563T 384 0.9938323210200224T 0 -28.100294451345775Z"
              ></animate>
            </path>
            <path d="" fill="url(#lg-0.7557432522653886)" opacity="0.4">
              <animate
                attributeName="d"
                dur="10s"
                repeatCount="indefinite"
                keyTimes="0;0.333;0.667;1"
                calcMode="spline"
                keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                begin="-5s"
                values="M0 0M 0 972.4807135755798Q 192 907.4019291373983 384 901.7265773488305T 768 796.570773593207T 1152 821.4955321710521T 1536 751.9882960982276T 1920 733.3235913001614L 1920 214.5003585956532Q 1728 244.7476667802398 1536 235.32809450347838T 1152 145.08378847110453T 768 106.8467982262963T 384 45.53361401822218T 0 20.81315097031836Z;M0 0M 0 917.7495760516803Q 192 929.4936556703053 384 924.3362168801734T 768 904.32254736692T 1152 842.2323366408733T 1536 767.1405766831543T 1920 653.8497472744364L 1920 268.5288533160642Q 1728 265.9276415824643 1536 257.2137807875459T 1152 100.29393793813404T 768 119.67973672085452T 384 -11.061657404965274T 0 20.072044790268762Z;M0 0M 0 999.1878787336531Q 192 953.1523386011556 384 944.8993634850012T 768 846.0098791642562T 1152 830.4429302267428T 1536 787.9733431565445T 1920 681.8405189206901L 1920 248.92962941277827Q 1728 237.6925831005439 1536 232.9308398742396T 1152 123.07627001543392T 768 59.361570582725165T 384 88.05348496109883T 0 -70.60578732586731Z;M0 0M 0 972.4807135755798Q 192 907.4019291373983 384 901.7265773488305T 768 796.570773593207T 1152 821.4955321710521T 1536 751.9882960982276T 1920 733.3235913001614L 1920 214.5003585956532Q 1728 244.7476667802398 1536 235.32809450347838T 1152 145.08378847110453T 768 106.8467982262963T 384 45.53361401822218T 0 20.81315097031836Z"
              ></animate>
            </path>
            <path d="" fill="url(#lg-0.7557432522653886)" opacity="0.4">
              <animate
                attributeName="d"
                dur="10s"
                repeatCount="indefinite"
                keyTimes="0;0.333;0.667;1"
                calcMode="spline"
                keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                begin="-7.5s"
                values="M0 0M 0 1014.0724839870097Q 192 954.045489910425 384 947.8656548588448T 768 898.158793489664T 1152 772.0335312613889T 1536 701.4960113129706T 1920 734.3472102784251L 1920 253.1544756327448Q 1728 241.01126313469132 1536 236.40486138907852T 1152 173.4798865267112T 768 48.1478592868873T 384 -8.643693535807813T 0 -65.4758677904754Z;M0 0M 0 985.4031605532724Q 192 867.7289385910243 384 859.2984358785028T 768 847.9001094368435T 1152 836.0638880313834T 1536 691.998135706121T 1920 631.6463736561807L 1920 243.8821516756798Q 1728 255.5777290605059 1536 254.00368087083052T 1152 107.18892439903871T 768 103.25727793665885T 384 48.26660284494676T 0 -40.60749830976735Z;M0 0M 0 1019.425551044801Q 192 893.154946185822 384 887.165846103437T 768 808.0800005087207T 1152 799.8844228951561T 1536 740.097383691696T 1920 654.5670918907695L 1920 219.04620296037322Q 1728 161.29608432003351 1536 152.38498543159898T 1152 150.9402376811761T 768 48.849829788469066T 384 -7.6480503497749055T 0 -60.61636611947603Z;M0 0M 0 1014.0724839870097Q 192 954.045489910425 384 947.8656548588448T 768 898.158793489664T 1152 772.0335312613889T 1536 701.4960113129706T 1920 734.3472102784251L 1920 253.1544756327448Q 1728 241.01126313469132 1536 236.40486138907852T 1152 173.4798865267112T 768 48.1478592868873T 384 -8.643693535807813T 0 -65.4758677904754Z"
              ></animate>
            </path>
          </g>
        </svg>
      </div>
      <div id="svgSmallDesktop">
        <img src="assets/staticSmall.png" alt="Small Desktop" />
      </div>
      <header className="masthead">
        <div
          className="container  d-flex h-100 justify-content-center"
          style={{ zIndex: 100 }}
        >
          <div className="d-flex justify-content-center">
            <div className="text-center masthead-text">
              <h1 className="mx-auto my-0 text-uppercase">Product Prompt</h1>
              <h2 className="text-white-50 mx-auto mt-2 mb-4" id="headertext">
                No-code prompt engineering
              </h2>
              <div className="signupWrap">
                <a href="https://app.productprompt.ai">
                  <button className="btn signupButton">SIGN UP</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="pt-5 pb-5 pink-background">
        <div className="container px-4 px-lg-5 text-center mb-2">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-10">
              <h2 className="text-black mb-4">
                Discover the best prompts for your features
              </h2>
              {/* <div class="col-xl-6 col-lg-6"> */}
              <div class="videoWrapper">
                <iframe src="https://www.youtube.com/embed/KzdlHtc-pb0"></iframe>
              </div>
              {/* </div> */}
              <p className="text-black-50 section-text component-under section-text-first">
                With Product Prompt, iterating on LLM prompts is easy. Now,
                anyone on your team can experiment, test, and find the best GPT
                prompt using your product data for your next killer feature.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5  blue-background">
        <div className="container px-4 px-lg-5 text-center mb-2">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-10">
              <h2 className="text-black mb-4">1. Upload your data</h2>
              {/* <p className="text-black-50 section-text">
                Upload your sample data in a JSON.
              </p> */}
              <Import params={params} setParams={setParams} />
              <p className="text-black-50 section-text component-under">
                Set up your JSON only once with the information you have for
                LLM. Then, you can use any combination of this data in your GPT
                prompts with no engineering time.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 blue-background">
        <div className="container px-4 px-lg-5 text-center mb-2">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-10">
              <h2 className="text-black mb-4">2. Experiment with prompts</h2>
              {/* <p className="text-black-50 section-text">
                Easily insert your data using rich-text format.
              </p> */}
              <Editor params={params} setParams={setParams} />
              <p className="text-black-50 section-text component-under component-under-2">
                With the templating language, you can conveniently change the
                text instruction for LLM and also decide what product
                information to include and how to organize it.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 blue-background">
        <div className="container px-4 px-lg-5 text-center mb-2">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-10">
              <h2 className="text-black mb-4">3. Find the perfect output</h2>
              {/* <p className="text-black-50 section-text">
                Easily iterate until you find the perfect output
              </p> */}
              <Result params={params} setParams={setParams} />
              <p className="text-black-50 section-text component-under component-under-3">
                Now, all team members, not just developers, can become prompt
                engineers. This allows for fast prototyping and testing of
                prompts to optimize new and existing features.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="features-icons text-center pt-5 pb-5 pink-background">
        <div className="container px-4 px-lg-5 text-center mb-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-7">
              <h2 className="text-black mb-4">Prompt engineering done right</h2>
              {/* <p className="text-black-50 section-text">
                Iterating on AI prompts can be fast and not require engineers.
              </p> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <h3>Privacy</h3>
                <p className="lead mb-0">
                  Setup your product data with sensitive information replaced.
                  Easily share and prototype features without exposing customer
                  data.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                <h3>History</h3>
                <p className="lead mb-0">
                  Every prompt parameter is auto-saved with each run. Freely
                  experiment without worring about losing your best prompt
                  setup.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                <h3>Sharing</h3>
                <p className="lead mb-0">
                  Share your entire prompt setup with a single click. Team
                  members can view the experiment history and results and build
                  on top of it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="signupwrap">
        <section className="signup-section" id="signup">
          <div id="svgLargeSignup">
            <svg
              // xmlns="http://www.w3.org/2000/svg"
              // xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "#ffffff",
                display: "block",
                zIndex: -10,
                position: "absolute",
                bottom: 0,
                marginBottom: "-110px",
              }}
              width="1920"
              height="944"
              // preserveAspectRatio="xMidYMid"
              preserveAspectRatio="none"
              viewBox="0 0 1920 944"
            >
              <g transform="translate(960,472) scale(1,-1) translate(-960,-472)">
                <linearGradient
                  id="lg-0.6093272723808045"
                  x1="1"
                  x2="0"
                  y1="0"
                  y2="0"
                >
                  <stop stopColor="#00ffff" offset="0"></stop>
                  <stop stopColor="#ff00ff" offset="1"></stop>
                </linearGradient>
                <path d="" fill="url(#lg-0.6093272723808045)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="10s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="0s"
                    values="M0 0M 0 817.3539819629655Q 192 838.0296599260099 384 832.6378251939311T 768 796.0089546401423T 1152 792.4038763591959T 1536 716.6404355849087T 1920 700.7337072457109L 1920 290.3842294078608Q 1728 184.71782143104508 1536 177.98684464984598T 1152 155.51651348281018T 768 147.7186094069602T 384 122.2421836541881T 0 105.89546434429806Z;M0 0M 0 852.6099858248504Q 192 800.5092905658112 384 799.4787420570756T 768 785.6093385642449T 1152 719.3329912693297T 1536 760.6968211132573T 1920 637.0857874327014L 1920 236.25098838821557Q 1728 240.05391096207873 1536 233.1729594153544T 1152 246.52358693547444T 768 201.12528048147502T 384 101.1696463198364T 0 40.853462663217584Z;M0 0M 0 799.7235387923837Q 192 783.4724601632279 384 779.3606153189121T 768 775.5031725584624T 1152 706.8097963708641T 1536 691.5197660052783T 1920 725.5060866712529L 1920 293.594538662263Q 1728 272.6934462829772 1536 265.71381594257986T 1152 250.1503673238006T 768 114.91646310902905T 384 179.89456030774338T 0 108.109055917733Z;M0 0M 0 817.3539819629655Q 192 838.0296599260099 384 832.6378251939311T 768 796.0089546401423T 1152 792.4038763591959T 1536 716.6404355849087T 1920 700.7337072457109L 1920 290.3842294078608Q 1728 184.71782143104508 1536 177.98684464984598T 1152 155.51651348281018T 768 147.7186094069602T 384 122.2421836541881T 0 105.89546434429806Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.6093272723808045)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="10s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-2.5s"
                    values="M0 0M 0 868.492208643649Q 192 794.9571030019224 384 787.300446121977T 768 762.8291100504898T 1152 694.3870343885477T 1536 665.0944817824283T 1920 640.3977859362952L 1920 243.00139035437957Q 1728 243.92069125788498 1536 241.0981420586374T 1152 235.7098951917892T 768 211.3950715519368T 384 97.12703084770621T 0 46.40536131439592Z;M0 0M 0 860.2229683165727Q 192 841.6418934522045 384 831.7643953112453T 768 780.8171149483514T 1152 717.7872271385905T 1536 677.9703555916487T 1920 691.7575498843266L 1920 255.24313667871513Q 1728 195.44635956341756 1536 187.70256005089902T 1152 223.01433463889668T 768 133.18021751639907T 384 166.59506752135687T 0 73.48306083849559Z;M0 0M 0 877.8522465167563Q 192 762.3543310239291 384 760.8086800073139T 768 745.8932656150107T 1152 800.8679727391008T 1536 753.6774271487138T 1920 731.4412800823327L 1920 250.54919499492863Q 1728 255.67202456281953 1536 252.22600319660393T 1152 143.22208949350988T 768 127.76604335337049T 384 88.38053481035473T 0 76.01316939663764Z;M0 0M 0 868.492208643649Q 192 794.9571030019224 384 787.300446121977T 768 762.8291100504898T 1152 694.3870343885477T 1536 665.0944817824283T 1920 640.3977859362952L 1920 243.00139035437957Q 1728 243.92069125788498 1536 241.0981420586374T 1152 235.7098951917892T 768 211.3950715519368T 384 97.12703084770621T 0 46.40536131439592Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.6093272723808045)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="10s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-5s"
                    values="M0 0M 0 831.4478719790919Q 192 771.7653915210356 384 771.5491506748662T 768 833.6430611313247T 1152 742.2406740140466T 1536 740.1626609051425T 1920 634.6974751604291L 1920 280.01263006755795Q 1728 232.82246691324067 1536 225.80383926817802T 1152 246.74430435080336T 768 116.80348716309621T 384 92.20184542098582T 0 66.68203465385287Z;M0 0M 0 846.5103203384696Q 192 820.9507078956343 384 811.4152808160134T 768 726.3760076976723T 1152 703.4826854653239T 1536 678.2538168682781T 1920 714.598365066576L 1920 246.3305073781513Q 1728 208.8272840968622 1536 200.1497436032102T 1152 170.35556215722062T 768 117.898951903827T 384 124.89262224191074T 0 142.99334186398548Z;M0 0M 0 815.2480798600976Q 192 763.7307518868465 384 759.5873846013111T 768 825.7190541074955T 1152 763.4614091486368T 1536 764.3337296288241T 1920 639.6979384490479L 1920 216.66034431643558Q 1728 287.30174136944845 1536 280.1845629054156T 1152 248.6692296784006T 768 209.5384475630747T 384 119.27192581804113T 0 81.48385126091281Z;M0 0M 0 831.4478719790919Q 192 771.7653915210356 384 771.5491506748662T 768 833.6430611313247T 1152 742.2406740140466T 1536 740.1626609051425T 1920 634.6974751604291L 1920 280.01263006755795Q 1728 232.82246691324067 1536 225.80383926817802T 1152 246.74430435080336T 768 116.80348716309621T 384 92.20184542098582T 0 66.68203465385287Z"
                  ></animate>
                </path>
                <path d="" fill="url(#lg-0.6093272723808045)" opacity="0.4">
                  <animate
                    attributeName="d"
                    dur="10s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    calcmod="spline"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-7.5s"
                    values="M0 0M 0 796.3277224662747Q 192 850.2523841137698 384 843.4401790221144T 768 801.9185580694285T 1152 758.9551771173063T 1536 673.6420887679834T 1920 692.4916812306673L 1920 284.42266786821335Q 1728 205.44430419813136 1536 204.95501722922975T 1152 238.47680918403046T 768 130.5536413681007T 384 101.87999567186739T 0 66.58660890932225Z;M0 0M 0 880.4927851582025Q 192 777.6521040435518 384 775.5251207199744T 768 780.5967060061088T 1152 764.0660331224879T 1536 671.2117349226579T 1920 650.5242820093413L 1920 224.1861120470089Q 1728 220.54764475816705 1536 211.39603370816826T 1152 204.4020729905899T 768 206.57965940563363T 384 103.36971690774686T 0 56.859200617967105Z;M0 0M 0 796.6576048542303Q 192 796.1648827717211 384 794.9728080246541T 768 757.2004988323612T 1152 714.6528965556085T 1536 725.067523021211T 1920 663.6488790251053L 1920 259.05156522608127Q 1728 183.40699765479678 1536 178.01499025821323T 1152 212.6156317906774T 768 166.4996139872484T 384 143.36437740598117T 0 72.18717615153915Z;M0 0M 0 796.3277224662747Q 192 850.2523841137698 384 843.4401790221144T 768 801.9185580694285T 1152 758.9551771173063T 1536 673.6420887679834T 1920 692.4916812306673L 1920 284.42266786821335Q 1728 205.44430419813136 1536 204.95501722922975T 1152 238.47680918403046T 768 130.5536413681007T 384 101.87999567186739T 0 66.58660890932225Z"
                  ></animate>
                </path>
              </g>
            </svg>
          </div>
          <div id="svgSmallSignup">
            <img src="assets/staticSignup.png" alt="Signup" />
          </div>
          <div className="container px-4 px-lg-5" id="signup">
            <div className="row gx-4 gx-lg-5">
              <div className="col-md-10 col-lg-8 mx-auto text-center svgBottomText">
                <h2 className="text-white" id="join">
                  Try Product Prompt for free
                </h2>

                <p className="text-white-50 section-text">
                  No credit card required. Premium at $29/month.
                  <br />
                </p>
                <p className="text-white-50 section-text mb-4"></p>
                <div className="col-auto">
                  <div className="row input-group-newsletter">
                    <a href="https://app.productprompt.ai">
                      <button className="btn signupButtonAfter">SIGN UP</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={termsModalIsOpen}
        onRequestClose={() => setTermsModalIsOpen(false)}
      >
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Terms of service
          </h1>
          <button
            type="button"
            class="btn-close"
            onClick={() => setTermsModalIsOpen(false)}
          ></button>
        </div>
        <div class="modal-body">
          <h2>
            <strong>Terms and Conditions</strong>
          </h2>
          <p>Welcome to Product Prompt!</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Product Prompt's Website, located at
            https://productprompt.ai.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Product Prompt if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>
          <h3>
            <strong>Cookies</strong>
          </h3>
          <p>
            We employ the use of cookies. By accessing Product Prompt, you
            agreed to use cookies in agreement with the Product Prompt's Privacy
            Policy.{" "}
          </p>
          <p>
            Most interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>
          <h3>
            <strong>License</strong>
          </h3>
          <p>
            Unless otherwise stated, Product Prompt and/or its licensors own the
            intellectual property rights for all material on Product Prompt. All
            intellectual property rights are reserved. You may access this from
            Product Prompt for your own personal use subjected to restrictions
            set in these terms and conditions.
          </p>
          <p>You must not:</p>
          <ul>
            <li>Republish material from Product Prompt</li>
            <li>Sell, rent or sub-license material from Product Prompt</li>
            <li>Reproduce, duplicate or copy material from Product Prompt</li>
            <li>Redistribute content from Product Prompt</li>
          </ul>
          <p>
            This Agreement shall begin on the date hereof. Our Terms and
            Conditions were created with the help of the{" "}
            <a href="https://www.termsandconditionsgenerator.com/">
              Free Terms and Conditions Generator
            </a>
            .
          </p>
          <p>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            Product Prompt does not filter, edit, publish or review Comments
            prior to their presence on the website. Comments do not reflect the
            views and opinions of Product Prompt,its agents and/or affiliates.
            Comments reflect the views and opinions of the person who post their
            views and opinions. To the extent permitted by applicable laws,
            Product Prompt shall not be liable for the Comments or for any
            liability, damages or expenses caused and/or suffered as a result of
            any use of and/or posting of and/or appearance of the Comments on
            this website.
          </p>
          <p>
            Product Prompt reserves the right to monitor all Comments and to
            remove any Comments which can be considered inappropriate, offensive
            or causes breach of these Terms and Conditions.
          </p>
          <p>You warrant and represent that:</p>
          <ul>
            <li>
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </li>
            <li>
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </li>
            <li>
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </li>
            <li>
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </li>
          </ul>
          <p>
            You hereby grant Product Prompt a non-exclusive license to use,
            reproduce, edit and authorize others to use, reproduce and edit any
            of your Comments in any and all forms, formats or media.
          </p>
          <h3>
            <strong>Hyperlinking to our Content</strong>
          </h3>
          <p>
            The following organizations may link to our Website without prior
            written approval:
          </p>
          <ul>
            <li>Government agencies;</li>
            <li>Search engines;</li>
            <li>News organizations;</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </li>
            <li>
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </li>
          </ul>
          <p>
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party’s site.
          </p>
          <p>
            We may consider and approve other link requests from the following
            types of organizations:
          </p>
          <ul>
            <li>
              commonly-known consumer and/or business information sources;
            </li>
            <li>dot.com community sites;</li>
            <li>associations or other groups representing charities;</li>
            <li>online directory distributors;</li>
            <li>internet portals;</li>
            <li>accounting, law and consulting firms; and</li>
            <li>educational institutions and trade associations.</li>
          </ul>
          <p>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Product
            Prompt; and (d) the link is in the context of general resource
            information.
          </p>
          <p>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </p>
          <p>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Product Prompt. Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </p>
          <p>Approved organizations may hyperlink to our Website as follows:</p>
          <ul>
            <li>By use of our corporate name; or</li>
            <li>By use of the uniform resource locator being linked to; or</li>
            <li>
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party’s site.
            </li>
          </ul>
          <p>
            No use of Product Prompt's logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>
          <h3>
            <strong>iFrames</strong>
          </h3>
          <p>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>
          <h3>
            <strong>Content Liability</strong>
          </h3>
          <p>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>
          <h3>
            <strong>Your Privacy</strong>
          </h3>
          <p>Please read Privacy Policy</p>
          <h3>
            <strong>Reservation of Rights</strong>
          </h3>
          <p>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it’s linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>
          <h3>
            <strong>Removal of links from our website</strong>
          </h3>
          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>
          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <h3>
            <strong>Disclaimer</strong>
          </h3>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>
          <ul>
            <li>
              limit or exclude our or your liability for death or personal
              injury;
            </li>
            <li>
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </li>
            <li>
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </li>
            <li>
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>
          <p>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </p>
          <p>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={privacyModalIsOpen}
        onRequestClose={() => setPrivacyModalIsOpen(false)}
      >
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Privacy policy
          </h1>
          <button
            type="button"
            class="btn-close"
            onClick={() => setPrivacyModalIsOpen(false)}
          ></button>
        </div>
        <div class="modal-body">
          <h1>Privacy Policy</h1>
          <p>Last updated: February 01, 2023</p>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
            <a
              href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
              target="_blank"
              rel="noreferrer"
            >
              Free Privacy Policy Generator
            </a>
            .
          </p>
          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2>Definitions</h2>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Product Prompt.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: California, United States
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to Product Prompt, accessible
                from{" "}
                <a
                  href="https://productprompt.ai"
                  rel="external nofollow noopener noreferrer"
                  target="_blank"
                >
                  https://productprompt.ai
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>Usage Data</p>
            </li>
          </ul>
          <h3>Usage Data</h3>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3>Tracking Technologies and Cookies</h3>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul>
            <li>
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on Your Device. You can instruct Your browser to
              refuse all Cookies or to indicate when a Cookie is being sent.
              However, if You do not accept Cookies, You may not be able to use
              some parts of our Service. Unless you have adjusted Your browser
              setting so that it will refuse Cookies, our Service may use
              Cookies.
            </li>
            <li>
              <strong>Web Beacons.</strong> Certain sections of our Service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </li>
          </ul>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. Learn more about cookies on the{" "}
            <a
              href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
              target="_blank"
              rel="noreferrer"
            >
              Free Privacy Policy website
            </a>{" "}
            article.
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul>
            <li>
              <p>
                <strong>Necessary / Essential Cookies</strong>
              </p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Functionality Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h2>Use of Your Personal Data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <p>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
            </li>
            <li>
              <p>
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </p>
            </li>
            <li>
              <p>
                <strong>For business transfers:</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </p>
            </li>
            <li>
              <p>
                <strong>For other purposes</strong>: We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </p>
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              <strong>With Service Providers:</strong> We may share Your
              personal information with Service Providers to monitor and analyze
              the use of our Service, to contact You.
            </li>
            <li>
              <strong>For business transfers:</strong> We may share or transfer
              Your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or
              acquisition of all or a portion of Our business to another
              company.
            </li>
            <li>
              <strong>With Affiliates:</strong> We may share Your information
              with Our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include Our
              parent company and any other subsidiaries, joint venture partners
              or other companies that We control or that are under common
              control with Us.
            </li>
            <li>
              <strong>With business partners:</strong> We may share Your
              information with Our business partners to offer You certain
              products, services or promotions.
            </li>
            <li>
              <strong>With other users:</strong> when You share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside.
            </li>
            <li>
              <strong>With Your consent</strong>: We may disclose Your personal
              information for any other purpose with Your consent.
            </li>
          </ul>
          <h2>Retention of Your Personal Data</h2>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h2>Delete Your Personal Data</h2>
          <p>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p>
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p>
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p>
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3>Law enforcement</h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3>Other legal requirements</h3>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h2>Security of Your Personal Data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h1>Children's Privacy</h1>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h1>Links to Other Websites</h1>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1>Changes to this Privacy Policy</h1>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1>Contact Us</h1>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>By email: hi@productprompt.ai</li>
          </ul>
        </div>
      </Modal>
      <section id="footer">
        <div className="footer-wrap container">
          <div className="footer-left">
            <div
              className="footer-item"
              id="termLink"
              onClick={() => setTermsModalIsOpen(true)}
            >
              Terms of service
            </div>
            <div
              className="footer-item"
              id="privacyLink"
              onClick={() => setPrivacyModalIsOpen(true)}
            >
              Privacy policy
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-item">
              <a href="mailto:hi@productprompt.ai">Contact</a>
            </div>
            <div>Copyright © 2023 Product Prompt</div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Admin;
