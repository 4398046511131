import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCwozhaZGgRIrQZqLFB6IRFXak6KtY7k-o",
  authDomain: "productprompt-251dd.firebaseapp.com",
  projectId: "productprompt-251dd",
  storageBucket: "productprompt-251dd.appspot.com",
  messagingSenderId: "937113452419",
  appId: "1:937113452419:web:9b64131ff9572e526340b6",
  measurementId: "G-8LS4QHYX4E",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

// export default app;
